<template>
  <ca-card
    v-if="(chats && chats.length > 0)"
    class="mb-3"
  >
    <template #header>
      <h4 class="mb-0">
        {{ heading ? heading : 'Korrespondenzen zu diesem Fahrzeug' }}
      </h4>
    </template>
    <template #body>
      <div
        v-for="(item, index) in chats"
        :key="item._id"
        class="row align-items-center py-2 cursor-pointer"
        :class="{'bg-primary-light-light': (index === currentChat)}"
        @click="goToChat(index)"
      >
        <div class="col-auto pr-2">
          <div class="user-icon-wrapper d-flex justify-content-center">
            <i class="far fa-user fa-lg text-primary align-self-center" />
          </div>
        </div>
        <div class="col pl-2">
          <p class="font-weight-bold mb-0">
            {{ item.chatIds.join(', ') }}
          </p>
          <p
            v-if="item.messages && item.messages.length > 0"
            class="mb-0"
          >
            <span class="text-primary">{{ (me.chatId === item.messages[item.messages.length-1].fromId) ? 'Ich: ': '' }}</span>
            {{ item.messages[item.messages.length-1].message }}
          </p>
        </div>
        <div class="col-auto">
          <small v-if="item.messages && item.messages.length > 0">
            {{ formatTime(item.messages[item.messages.length-1].createdAt) }}
          </small>
        </div>
      </div>
    </template>
  </ca-card>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
  .user-icon-wrapper{
    $size: 50px;
    background-color: $secondary;
    width: $size;
    height: $size;
    border-radius: 100px;
  }
</style>

<script>
import formatDate from '@/filters/formatDate.js'
import moment from 'moment'
import CaCard from '@/components/Card'

export default {
  name: 'CarChatList',
  components: {
    CaCard
  },
  props: {
    heading: {
      default: undefined,
      type: String
    }
  },
  data () {
    return {
      chat: undefined,
      currentChat: undefined,
      chats: undefined,
      car: null
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    formatTime (myDate) {
      const today = moment()
      if (moment(myDate).isSame(today, 'day')) {
        return formatDate(myDate, 'HH:mm')
      } else {
        return formatDate(myDate, 'DD.MM.YYYY')
      }
    },
    goToChat (i) {
      this.currentChat = i
      this.$emit('currentChat', this.currentChat)
    },
    async fetchData () {
      try {
        this.loading = true
        this.car = await this.$store.dispatch('car/get', this.$route.params.carId)
        this.chats = await this.$store.dispatch('car-chat/find', {
          query: {
            $client: {
              paginate: false
            },
            carId: this.car._id
          }
        })
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
