<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4 class="mb-0">
          Weitere Daten
        </h4>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 col-md-4">
            <h5>
              Fahrzeug
            </h5>
            <ul>
              <li
                v-if="carAdditionalInfo.numberOfDoors"
              >
                {{ getDoors(carAdditionalInfo.numberOfDoors) }} Türen {{ getSlidingDoors(carAdditionalInfo.slidingDoor) ? '- ' + getSlidingDoors(carAdditionalInfo.slidingDoor): '' }}
              </li>
              <li
                v-if="carAdditionalInfo.constructionShape"
              >
                {{ getConstructionShape(carAdditionalInfo.constructionShape) }}
              </li>
              <li
                v-if="carAdditionalInfo.numberOfSeats"
              >
                {{ carAdditionalInfo.numberOfSeats }} Sitzplätze
              </li>

              <li>
                Außenfarbe: {{ getColorOutside(carAdditionalInfo.colorOutside) }} {{ carAdditionalInfo.metallic ? 'Metallic': '' }}
              </li>
              <li>
                Innenausstattung: {{ getMaterialInside(carAdditionalInfo.materialInside) && getColorInside(carAdditionalInfo.colorInside)? getMaterialInside(carAdditionalInfo.materialInside) + ', ' : '' }}{{ getColorInside(carAdditionalInfo.colorInside) }}
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <h5>
              Fahrzeughistorie
            </h5>
            <ul>
              <li
                v-if="carAdditionalInfo.typeOfCar"
              >
                {{ getTypeOfCar(carAdditionalInfo.typeOfCar) }}
              </li>
              <li
                v-if="carAdditionalInfo.numberOfCarOwner"
              >
                {{ carAdditionalInfo.numberOfCarOwner }} Fahrzeughalter
              </li>
              <li
                v-if="carAdditionalInfo.roadworthy"
              >
                Fahrtauglich
              </li>
              <li
                v-if="carAdditionalInfo.nonSmokingVehicle"
              >
                Nichtraucherfahrzeug
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <h5>
              Umwelt
            </h5>
            <ul>
              <li
                v-if="carAdditionalInfo.pollutantClass"
              >
                Schadstoffklasse {{ getPollutantClass(carAdditionalInfo.pollutantClass) }}
              </li>
              <li
                v-if="carAdditionalInfo.environmentalBadge"
              >
                Umweltplakette {{ getEnvironmentalBadge(carAdditionalInfo.environmentalBadge) }}
              </li>
              <li
                v-if="carAdditionalInfo.startStopAutomatic"
              >
                Start / Stopp-Automatik
              </li>
              <li
                v-if="carAdditionalInfo.particleFilter"
              >
                Partikelfilter
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <h5>
              Antrieb
            </h5>
            <ul>
              <li
                v-if="carAdditionalInfo.transmission"
              >
                {{ getTransmission(carAdditionalInfo.transmission) }} {{ carAdditionalInfo.gearshiftPaddles ? '- Schaltwippen' : '' }}
              </li>
              <li
                v-if="carAdditionalInfo.allWhellDrive"
              >
                Allradantrieb
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <h5>
              Wartung und Inspektion
            </h5>
            <ul>
              <li>
                HU gültig bis {{ getHUDateOfExpireMonth(carAdditionalInfo.HUDateOfExpireMonth) }}
                {{ carAdditionalInfo.HUDateOfExpireYear }}
              </li>
              <li
                v-if="carAdditionalInfo.maintenanceGuide"
              >
                Scheckheftgepflegt
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <h5>
              Garantien
            </h5>
            <ul>
              <li
                v-if="carAdditionalInfo.manufacturerWarranty"
              >
                Herstellergarantie
              </li>
              <li
                v-if="carAdditionalInfo.usedCarWarrantyPossible"
              >
                Gebrauchtwagengarantie möglich
              </li>
            </ul>
          </div>
        </div>
      </template>
    </ca-card>
    <ca-card
      v-if="carAdditionalInfo.Note"
      class="mb-3"
    >
      <template #header>
        <h4 class="mb-0">
          Fahrzeugbeschreibung
        </h4>
      </template>
      <template #body>
        <p class="mb-0">
          {{ carAdditionalInfo.Note }}
        </p>
      </template>
    </ca-card>

    <ca-card class="mb-3">
      <template #header>
        <h4 class="mb-0">
          Ausstattung
        </h4>
      </template>
      <template #body>
        <div class="row">
          <div
            v-if="carAdditionalInfo.abs"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Antiblockiersystem (ABS)
          </div>
          <div
            v-if="carAdditionalInfo.esp"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektronisches Stabilitätsprogramm (ESP)
          </div>
          <div
            v-if="carAdditionalInfo.tractionControll"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Traktionskontrolle (ASR)
          </div>
          <div
            v-if="carAdditionalInfo.hillStartAssist"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Bergfahrassistent
          </div>
          <div
            v-if="carAdditionalInfo.fatigueWarning"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Müdigkeitswarner
          </div>
          <div
            v-if="carAdditionalInfo.laneDepartureAssistant"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Spurhalteassistent
          </div>
          <div
            v-if="carAdditionalInfo.bilndSpotAssistant"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Totwinkel-Assistent
          </div>
          <div
            v-if="carAdditionalInfo.automaticInteriorMirror"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Innenspiegel autom. abblendend
          </div>
          <div
            v-if="carAdditionalInfo.nightVisionAssistant"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Nachtsicht-Assistent
          </div>
          <div
            v-if="carAdditionalInfo.emergencyBrakeAssistant"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Notbremsassistent
          </div>
          <div
            v-if="carAdditionalInfo.emergencyCallSystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Notrufsystem
          </div>
          <div
            v-if="carAdditionalInfo.trafficSignRecognition"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Verkehrszeichenerkennung
          </div>
          <div
            v-if="carAdditionalInfo.cruiseControl"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getCruiseControl(carAdditionalInfo.cruiseControl) }}
          </div>
          <div
            v-if="carAdditionalInfo.speedLimiter"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Geschwindigkeitsbegrenzer
          </div>
          <div
            v-if="carAdditionalInfo.distanceWarning"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Abstandswarner
          </div>
          <div
            v-if="carAdditionalInfo.airbags"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getAirbags(carAdditionalInfo.airbags) }}
          </div>
          <div
            v-if="carAdditionalInfo.isofixCildSeat"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Isofix (Kindersitzbefestigung)
          </div>
          <div
            v-if="carAdditionalInfo.isofixPassengerSeat"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Isofix Beifahrersitz
          </div>
          <div
            v-if="carAdditionalInfo.typeOfHeadlights"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getTypeOfHeadlights(carAdditionalInfo.typeOfHeadlights) }}
          </div>
          <div
            v-if="carAdditionalInfo.headlightsWasher"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Scheinwerferreinigung
          </div>
          <div
            v-if="carAdditionalInfo.GlareFreeHighBeam"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Blendfreies Fernlicht
          </div>
          <div
            v-if="carAdditionalInfo.highBeamAssistant"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Fernlichtassistent
          </div>
          <div
            v-if="carAdditionalInfo.daytimeLights"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getDaytimeLights(carAdditionalInfo.daytimeLights) }}
          </div>
          <div
            v-if="carAdditionalInfo.bendLighting"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getBendLighting(carAdditionalInfo.bendLighting) }}
          </div>
          <div
            v-if="carAdditionalInfo.fogLights"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Nebelscheinwerfer
          </div>
          <div
            v-if="carAdditionalInfo.alarmSystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Alarmanlage
          </div>
          <div
            v-if="carAdditionalInfo.electricImmobilizer"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektrische Wegfahrsperre
          </div>
          <div
            v-if="carAdditionalInfo.airConditioning"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getAirConditioning(carAdditionalInfo.airConditioning) }}
          </div>
          <div
            v-if="carAdditionalInfo.heater"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Standheizung
          </div>
          <div
            v-if="carAdditionalInfo.heatedWindscreen"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Beheizte Frontscheibe
          </div>
          <div
            v-if="carAdditionalInfo.heatedSteeringWheel"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Beheizbares Lenkrad
          </div>
          <div
            v-if="carAdditionalInfo.SelfSteeringSystems"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Selbstlenkende Systeme
          </div>
          <div
            v-if="carAdditionalInfo.acousticParkingAssistanceFront"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Akustische Einparkhilfe Vorne
          </div>
          <div
            v-if="carAdditionalInfo.acousticParkingAssistanceBack"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Akustische Einparkhilfe Hinten
          </div>
          <div
            v-if="carAdditionalInfo.camera"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Kamera
          </div>
          <div
            v-if="carAdditionalInfo.surroundCamera"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> 360°-Kamera
          </div>
          <div
            v-if="carAdditionalInfo.heatedSeatsFront"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sitzheizung Vorne
          </div>
          <div
            v-if="carAdditionalInfo.heatedSeatsBack"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sitzheizung Hinten
          </div>
          <div
            v-if="carAdditionalInfo.electricSeatAdjustmentFront"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektrische Sitzeinstellung Vorne
          </div>
          <div
            v-if="carAdditionalInfo.electricSeatAdjustmentBack"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektrische Sitzeinstellung Hinten
          </div>
          <div
            v-if="carAdditionalInfo.sportSeats"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sportsitze
          </div>
          <div
            v-if="carAdditionalInfo.massageSeats"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Massagesitze
          </div>
          <div
            v-if="carAdditionalInfo.armrest"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Armlehne
          </div>
          <div
            v-if="carAdditionalInfo.seatVentilation"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sitzbelüftung
          </div>
          <div
            v-if="carAdditionalInfo.lumbarSupport"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Lordosenstütze
          </div>
          <div
            v-if="carAdditionalInfo.reversibleFrontPassengerSeat"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Umklappbarer Beifahrersitz
          </div>
          <div
            v-if="carAdditionalInfo.electricWindows"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektrische Fensterheber
          </div>
          <div
            v-if="carAdditionalInfo.electricSideMirrors"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektrische Seitenspiegel
          </div>
          <div
            v-if="carAdditionalInfo.electricTailgate"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Elektrische Heckklappe
          </div>
          <div
            v-if="carAdditionalInfo.centralLockingSystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Zentralverriegelung
          </div>
          <div
            v-if="carAdditionalInfo.keylessCentralLockingSystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Schlüssellose Zentralverriegelung
          </div>
          <div
            v-if="carAdditionalInfo.lightSensor"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Lichtsensor
          </div>
          <div
            v-if="carAdditionalInfo.rainSensor"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Regensensor
          </div>
          <div
            v-if="carAdditionalInfo.powerSteering"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Servolenkung
          </div>
          <div
            v-if="carAdditionalInfo.ambientLight"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Ambiente-Beleuchtung
          </div>
          <div
            v-if="carAdditionalInfo.tunerRadio"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Tuner / Radio
          </div>
          <div
            v-if="carAdditionalInfo.radioDab"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Radio DAB
          </div>
          <div
            v-if="carAdditionalInfo.CDPlayer"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> CD-Spieler
          </div>
          <div
            v-if="carAdditionalInfo.tv"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> TV
          </div>
          <div
            v-if="carAdditionalInfo.navigationSystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Navigationssystem
          </div>
          <div
            v-if="carAdditionalInfo.soundsystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Soundsystem
          </div>
          <div
            v-if="carAdditionalInfo.touchscreen"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Touchscreen
          </div>
          <div
            v-if="carAdditionalInfo.voiceControl"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sprachsteuerung
          </div>
          <div
            v-if="carAdditionalInfo.usb"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> USB
          </div>
          <div
            v-if="carAdditionalInfo.bluetooth"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Bluetooth
          </div>
          <div
            v-if="carAdditionalInfo.appleCarPlay"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Apple CarPlay
          </div>
          <div
            v-if="carAdditionalInfo.androidAuto"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Android Auto
          </div>
          <div
            v-if="carAdditionalInfo.wifi"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> WLAN / Wifi Hotspot
          </div>
          <div
            v-if="carAdditionalInfo.musicStreaming"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Musikstreaming intigriert
          </div>
          <div
            v-if="carAdditionalInfo.inductionCharging"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Induktionsladen für Smartphones
          </div>
          <div
            v-if="carAdditionalInfo.boardComputer"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Bordcomputer
          </div>
          <div
            v-if="carAdditionalInfo.headUpDisplay"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Head-Up Display
          </div>
          <div
            v-if="carAdditionalInfo.digitalInstrument"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Volldigitales Komiinstrument
          </div>
          <div
            v-if="carAdditionalInfo.alloyWheels"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Leichtmetallfelgen
          </div>
          <div
            v-if="carAdditionalInfo.summerTires"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sommerreifen
          </div>
          <div
            v-if="carAdditionalInfo.winterTires"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Winterreifen
          </div>
          <div
            v-if="carAdditionalInfo.allSeasonTries"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Allwetterreifen
          </div>
          <div
            v-if="carAdditionalInfo.roadsideAssistance"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> {{ getRoadsideAssistance(carAdditionalInfo.roadsideAssistance) }}
          </div>
          <div
            v-if="carAdditionalInfo.tirePressureMonitoringSystem"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Reifendruckkontrolle
          </div>
          <div
            v-if="carAdditionalInfo.winterPackage"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Winterpaket
          </div>
          <div
            v-if="carAdditionalInfo.smokingPackage"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Raucherpaket
          </div>
          <div
            v-if="carAdditionalInfo.sportPackage"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sportpaket
          </div>
          <div
            v-if="carAdditionalInfo.sportsSuspension"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Sportfahrwerk
          </div>
          <div
            v-if="carAdditionalInfo.airSuspension"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Luftfederung
          </div>
          <div
            v-if="carAdditionalInfo.trailerHitch"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Anhängerkupplung
          </div>
          <div
            v-if="carAdditionalInfo.cargoBarrier"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Gepäckraumabtrennung
          </div>
          <div
            v-if="carAdditionalInfo.skiBag"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Skisack
          </div>
          <div
            v-if="carAdditionalInfo.sunroof"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Schiebedach
          </div>
          <div
            v-if="carAdditionalInfo.panoramicRoof"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Panorama-Dach
          </div>
          <div
            v-if="carAdditionalInfo.roofRails"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Dachreling
          </div>
          <div
            v-if="carAdditionalInfo.handicappedAccessible"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Behindertengerecht
          </div>
          <div
            v-if="carAdditionalInfo.taxi"
            class="col-12 col-md-6 col-xl-4"
          >
            <i class="far fa-check mr-2" /> Taxi
          </div>
        </div>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
export default {
  name: 'CarAdditionalDataDetail',
  components: {
    CaCard
  },
  props: {
    carAdditionalInfo: {
      type: Object,
      default: undefined
    }
  },
  methods: {

    getDoors (doors) {
      switch (doors) {
      case 'TWO_OR_THREE':
        return '2/3'
      case 'FOUR_OR_FIVE':
        return '4/5'
      case 'SIX_OR_SEVEN':
        return '6/7'
      }
    },
    getSlidingDoors (slidingDoors) {
      switch (slidingDoors) {
      case 'SLIDING_DOOR_RIGHT':
        return 'Schiebetür rechts'
      case 'SLIDING_DOOR_LEFT':
        return 'Schiebetür links'
      case 'SLIDING_DOOR_BOTH_SIDED':
        return 'Schiebetür beidseitig'
      case 'NONE':
        return undefined
      }
    },
    getConstructionShape (constructionShape) {
      switch (constructionShape) {
      case 'OtherCar':
        return 'Andere'
      case 'Cabrio':
        return 'Cabrio / Roadster'
      case 'SmallCar':
        return 'Kleinwagen'
      case 'EstateCar':
        return 'Kombi'
      case 'Limousine':
        return 'Limousine'
      case 'SportsCar':
        return 'Sportwagen / Coupé'
      case 'OffRoad':
        return 'SUV / Geländewagen / Pickup'
      case 'Van':
        return 'Van / Minibus'
      }
    },
    getTransmission (transmission) {
      switch (transmission) {
      case 'MANUAL_GEAR':
        return 'Schaltgetriebe'
      case 'SEMIAUTOMATIC_GEAR':
        return 'Halbautomatik'
      case 'AUTOMATIC_GEAR':
        return 'Automatik'
      }
    },
    getPollutantClass (pollutantClass) {
      switch (pollutantClass) {
      case 'EURO1':
        return 'Euro1'
      case 'EURO2':
        return 'Euro2'
      case 'EURO3':
        return 'Euro3'
      case 'EURO4':
        return 'Euro4'
      case 'EURO5':
        return 'Euro5'
      case 'EURO6':
        return 'Euro6'
      case 'EURO6C':
        return 'Euro6c'
      case 'EURO6D_TEMP':
        return 'Euro6d-TEMP'
      case 'EURO6D':
        return 'Euro6d'
      }
    },
    getEnvironmentalBadge (environmentalBadge) {
      switch (environmentalBadge) {
      case 'EMISSIONSSTICKER_GREEN':
        return '4 (Grün)'
      case 'EMISSIONSSTICKER_YELLOW':
        return '3 (Gelb)'
      case 'EMISSIONSSTICKER_RED':
        return '2 (Rot)'
      case 'EMISSIONSSTICKER_NONE':
        return '1 (Keine)'
      }
    },
    getTypeOfCar (typeOfCar) {
      switch (typeOfCar) {
      case 'USED_CAR':
        return 'Gebrauchtfahrzeug'
      case 'YEAR_CAR':
        return 'Jahreswagen'
      case 'NEW_CAR':
        return 'Neuwagen'
      }
    },
    getHUDateOfExpireMonth (HUDateOfExpireMonth) {
      switch (HUDateOfExpireMonth) {
      case 1:
        return 'Januar'
      case 2:
        return 'Februar'
      case 3:
        return 'März'
      case 4:
        return 'April'
      case 5:
        return 'Mai'
      case 6:
        return 'Juni'
      case 7:
        return 'Juli'
      case 8:
        return 'August'
      case 9:
        return 'September'
      case 10:
        return 'Oktober'
      case 11:
        return 'November'
      case 12:
        return 'Dezember'
      }
    },
    getColorOutside (colorOutside) {
      switch (colorOutside) {
      case 'BEIGE':
        return 'Beige'
      case 'BLUE':
        return 'Blau'
      case 'BROWN':
        return 'Braun'
      case 'YELLOW':
        return 'Gelb'
      case 'GOLD':
        return 'Gold'
      case 'GREY':
        return 'Grau'
      case 'GREEN':
        return 'Grün'
      case 'ORANGE':
        return 'Orange'
      case 'RED':
        return 'Rot'
      case 'BLACK':
        return 'Schwarz'
      case 'SILVER':
        return 'Silber'
      case 'PURPLE':
        return 'Violett'
      case 'WHITE':
        return 'Weiß'
      }
    },
    getMaterialInside (materialInside) {
      switch (materialInside) {
      case 'LEATHER':
        return 'Vollleder'
      case 'PARTIAL_LEATHER':
        return 'Teilleder'
      case 'FABRIC':
        return 'Stoff'
      case 'VELOUR':
        return 'Velours'
      case 'ALCANTARA':
        return 'Alcantara'
      case 'OTHER_INTERIOR_TYPE':
        return undefined
      }
    },
    getColorInside (colorInside) {
      switch (colorInside) {
      case 'BLACK':
        return 'Schwarz'
      case 'GREY':
        return 'Grau'
      case 'BEIGE':
        return 'Beige'
      case 'BROWN':
        return 'Braun'
      case 'OTHER_INTERIOR_COLOR':
        return undefined
      }
    },
    getCruiseControl (cruiseControl) {
      switch (cruiseControl) {
      case 'CRUISE_CONTROL':
        return 'Tempomat'
      case 'ADAPTIVE_CRUISE_CONTROL':
        return 'Abstandstempomat'
      }
    },
    getAirbags (airbags) {
      switch (airbags) {
      case 'DRIVER_AIRBAG':
        return 'Fahrer-Airbag'
      case 'FRONT_AIRBAGS':
        return 'Front-Airbags'
      case 'FRONT_AND_SIDE_AIRBAGS':
        return 'Front- und Seiten-Airbags'
      case 'FRONT_AND_SIDE_AND_MORE_AIRBAGS':
        return 'Front-, Seiten- und weitere Airbags'
      }
    },
    getTypeOfHeadlights (typeOfHeadlights) {
      switch (typeOfHeadlights) {
      case 'XENON_HEADLIGHTS':
        return 'Xenonscheinwerfer'
      case 'BI_XENON_HEADLIGHTS':
        return 'Bi-Xenon Scheinwerfer'
      case 'LED_HEADLIGHTS':
        return 'LED-Scheinwerfer'
      case 'LASER_HEADLIGHTS':
        return 'Laserlicht'
      }
    },
    getDaytimeLights (daytimeLights) {
      switch (daytimeLights) {
      case 'DAYTIME_RUNNING_LIGHTS':
        return 'Tagfahrlicht'
      case 'LED_RUNNING_LIGHTS':
        return 'LED-Tagfahrlicht'
      }
    },
    getBendLighting (bendLighting) {
      switch (bendLighting) {
      case 'BENDING_LIGHTS':
        return 'Kurvenlicht'
      case 'ADAPTIVE_BENDING_LIGHTS':
        return 'Adaptives Kurvenlicht'
      }
    },
    getAirConditioning (airConditioning) {
      switch (airConditioning) {
      case 'NO_CLIMATISATION':
        return 'Keine Klimaanlage oder -automatik'
      case 'MANUAL_CLIMATISATION':
        return 'Klimaanlage'
      case 'AUTOMATIC_CLIMATISATION':
        return 'Klimaautomatik'
      case 'AUTOMATIC_CLIMATISATION_2_ZONES':
        return '2-Zonen-Klimaautomatik'
      case 'AUTOMATIC_CLIMATISATION_3_ZONES':
        return '3-Zonen-Klimaautomatik'
      case 'AUTOMATIC_CLIMATISATION_4_ZONES':
        return '4-Zonen-Klimaautomatik'
      }
    },
    getRoadsideAssistance (roadsideAssistance) {
      switch (roadsideAssistance) {
      case 'SPARE_WHEEL':
        return 'Reserverad'
      case 'EMERGENCY_WHEEL':
        return 'Notrad'
      case 'REPAIR_KIT':
        return 'Pannenkit'
      }
    }
  }
}
</script>
