<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Fahrzeugdaten
      </h4>
    </template>
    <template #body>
      <div class="row">
        <div class="col-5">
          Land
        </div>
        <div class="col-7">
          {{ car.country.name }}
        </div>

        <div class="col-5">
          Kennzeichen
        </div>
        <div class="col-7">
          {{ car.licensePlate }}
        </div>

        <div class="col-5">
          Saisonkennzeichen von / bis
        </div>
        <div class="col-7">
          {{ car.saisonkennzeichenvon | monthOptions }} - {{ car.saisonkennzeichenbis | monthOptions }}
        </div>

        <div class="col-5">
          HSN - TSN
        </div>
        <div class="col-7">
          {{ car.hsn }} - {{ car.tsn }}
        </div>

        <div
          v-if="!car.noHsnTsn"
          class="col-5"
        >
          Hersteller - Typ
        </div>
        <div
          v-if="!car.noHsnTsn"
          class="col-7"
        >
          {{ car.producer }} - {{ car.type }}
        </div>

        <div class="col-5">
          {{ 'Typ' + (!car.noHsnTsn ? ' (Zusatz)' : '') }}
        </div>
        <div class="col-7">
          {{ car.typePolice }}
        </div>

        <div class="col-5">
          Fahrgestell Nummer
        </div>
        <div class="col-7">
          {{ car.chassi }}
        </div>

        <div class="col-5">
          Erstzulassung
        </div>
        <div class="col-7">
          {{ car.registrationDate | formatDate }}
        </div>

        <div class="col-5">
          Alter
        </div>
        <div class="col-7">
          {{ car.monthFromRegistrationDate }} Monat{{ car.monthFromRegistrationDate > 1 ? 'e' : '' }}
        </div>

        <div class="col-5">
          Leistung / Hubraum
        </div>
        <div class="col-7">
          {{ car.ccm }} ccm / {{ car.kw }} kW
        </div>

        <div class="col-5">
          km Stand
        </div>
        <div class="col-7">
          {{ car.km }} km (Stand {{ car.milageMeasureDate | formatDate }})
        </div>

        <div class="col-5">
          Kaufpreis
        </div>
        <div class="col-7">
          {{ car.price }} €
        </div>

        <div class="col-5">
          Kraftstoff
        </div>
        <div class="col-7">
          {{ car.fuel }}
        </div>

        <div class="col-5">
          Nutzungsart
        </div>
        <div class="col-7">
          {{ car.usage }}
        </div>
      </div>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
export default {
  name: 'CarDataDetail',
  components: {
    CaCard
  },
  filters: {
    formatDate,
    monthOptions (val) {
      switch (val) {
      case 0: return ''
      case 1: return 'Januar'
      case 2: return 'Februar'
      case 3: return 'März'
      case 4: return 'April'
      case 5: return 'Mai'
      case 6: return 'Juni'
      case 7: return 'Juli'
      case 8: return 'August'
      case 9: return 'September'
      case 10: return 'Oktober'
      case 11: return 'November'
      case 12: return 'Dezember'
      }
    }
  },
  props: {
    car: {
      type: Object,
      default: undefined
    }
  }
}
</script>
