<template>
  <div v-if="!loading">
    <div>
      <div class="card mb-3">
        <div
          v-if="chat"
          class="card-header bg-white"
        >
          <div class="form-row">
            <div class="col">
              <h5 class="mb-0">
                <template v-for="chatUserId in chat.chatIds">
                  {{ chatUserId }}
                </template>
              </h5>
            </div>
            <div
              v-if="close"
              class="col-auto"
            >
              <button
                class="btn btn-link p-0"
                @click="$emit('close')"
              >
                <i class="far fa-times" />
              </button>
            </div>
          </div>
        </div>
        <div
          ref="chatScroll"
          class="card-body text-window"
        >
          <template v-if="!chat">
            <div class="d-flex justify-content-center h-100">
              <div class="align-self-center">
                <i class="far fa-comment-lines fa-3x text-primary d-block text-center mb-2" />
                <h4 class="text-center">
                  kontaktiere den Händler<br>zu diesem Fahrzeug
                </h4>
              </div>
            </div>
          </template>
          <div
            v-else
            class="text-window-row"
          >
            <div class="row align-content-end">
              <template v-for="messageGroupedByDay in messagesGroupedByDay">
                <div
                  :key="messageGroupedByDay.day"
                  class="col-12 text-center mb-2"
                >
                  <div class="badge">
                    {{ messageGroupedByDay.day | formatDate('DD.MM.YYYY') }}
                  </div>
                </div>
                <template v-for="item in messageGroupedByDay.messages">
                  <div
                    :key="item._id"
                    class="col-9"
                    :class="{'ml-auto': (item.fromId === me.chatId)}"
                  >
                    <div
                      class="pl-3 pt-2 pb-0 pr-0 rounded"
                      :class="{'bg-primary': (item.fromId === me.chatId), 'bg-secondary': (item.fromId !== me.chatId)}"
                    >
                      <div
                        class="form-row mb-2"
                      >
                        <div class="col">
                          <p
                            class="mb-2"
                            :class="{'text-white': (item.fromId === me.chatId)}"
                          >
                            {{ item.message }}
                          </p>
                        </div>
                        <div class="col-auto align-self-end">
                          <small
                            class="d-block mb-1 mr-2"
                            :class="{'text-secondary': (item.fromId === me.chatId), 'text-primary': (item.fromId !== me.chatId)}"
                          >{{ item.createdAt | formatDate('HH:mm') }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="input-group">
          <input
            ref="newMessage"
            v-model="newMessage"
            placeholder="Schreib ein Nachricht..."
            type="text"
            class="form-control border-right-0 border-gray-2"
            @keyup.enter="sendMessage()"
          >
          <div class="input-group-append">
            <button
              class="btn btn-link btn-outline-gray-2 border-left-0 px-2"
              @click="sendMessage()"
            >
              <i class="far fa-paper-plane" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.text-window{
  height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track
  {
    background-color: white;
  }

  &::-webkit-scrollbar
  {
    width: 8px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: $secondary;
    transition: all 300ms;
    &:hover{
      background-color: $primary;
      transition: all 300ms;
    }
  }
  .text-window-row{
    min-height: 100%;
  }
}

</style>

<script>
import Vue from 'vue'
import moment from 'moment'
import formatDate from '@/filters/formatDate.js'

export default {
  name: 'CarChat',
  filters: {
    formatDate
  },
  props: {
    chatId: {
      default: '',
      type: String
    },
    close: {
      default: false,
      type: Boolean
    },
    carId: {
      default: '',
      type: String
    }
  },
  data: () => {
    return {
      loading: true,
      newMessage: '',
      currentDate: undefined,
      chat: null
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    sortedMessages () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.chat.messages.sort((b, a) => {
        a = new Date(a.createdAt)
        b = new Date(b.createdAt)
        return a > b ? -1 : a < b ? 1 : 0
      })
    },
    messagesGroupedByDay () {
      const groupedObject = {}
      for (const message of this.sortedMessages) {
        const day = moment(message.createdAt).format('l')
        groupedObject[day] = groupedObject[day] || {
          day: message.createdAt,
          messages: []
        }
        groupedObject[day].messages.push(message)
      }

      return Object.keys(groupedObject).map(day => {
        return groupedObject[day]
      })
    }
  },
  watch: {
    chatId: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async sendMessage () {
      try {
        if (!this.chat) {
          this.chat = await this.$store.dispatch('car-chat/create', {
            carId: this.carId,
            chatIds: [this.me.chatId]
          })
        }
        this.sendMessagePending = true
        await this.$store.dispatch('car-chat-message/create', {
          message: this.newMessage,
          carChatId: this.chat._id
        })
        await this.fetchData()
      } catch (error) {
        console.error(error)
      } finally {
        this.sendMessagePending = false
        this.scroll(1000)
        this.newMessage = ''
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.chat = await this.$store.dispatch('car-chat/get', this.chatId)
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
        this.scroll()
      }
    },

    scroll (delay) {
      var doScroll = () => {
        if (this.$refs.chatScroll) {
          this.$refs.chatScroll.scrollTop = this.$refs.chatScroll.scrollHeight
          this.$refs.newMessage.focus()
        }
      }

      if (delay) {
        setTimeout(function () {
          Vue.nextTick(doScroll)
        }, delay)
      } else { Vue.nextTick(doScroll) }
    }
  }
}
</script>
